import PropTypes from 'prop-types';

export const Airport = {
  name: PropTypes.string,
  code: PropTypes.string,
};

export const DateTime = {
  date: PropTypes.string,
  time: PropTypes.string,
};

export default {
  origin: PropTypes.shape(Airport),
  destination: PropTypes.shape(Airport),
  flightNumber: PropTypes.string,
  flightClass: PropTypes.string,
  departure: PropTypes.shape(DateTime),
  arrival: PropTypes.shape(DateTime),
  promotionCode: PropTypes.string,
};
