import accounting from 'accounting';

export const formatMoney = (amount, options = {}) => {
  const amountToFormat = options.wholeDollars ? Math.ceil(amount) : amount;
  const precision = options.wholeDollars ? 0 : 2;
  return accounting.formatMoney(amountToFormat, undefined, precision);
};

export const formatMoneyWithoutDollar = (amount, options = {}) => {
  const amountToFormat = options.wholeDollars ? Math.ceil(amount) : amount;
  const precision = options.wholeDollars ? 0 : 2;
  return accounting.formatMoney(amountToFormat, '', precision);
};

// eslint-disable-next-line max-len
export const formatMoneyWithCurrency = (price, options = {}) =>
  `${formatMoney(price.amount, options)}^ ${price.currency}`;

export default formatMoney;
